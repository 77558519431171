



.ui-datepicker {
    width: 18em;
    margin-top:14px;
    display: none;
    background: #fff;
    position:relative;
    font: 14px/1.55  "Roboto", Arial, Helvetica, sans-serif;
    -webkit-box-shadow: 0 0 4px rgba(0,0,0,.3);
    -moz-box-shadow: 0 0 4px rgba(0,0,0,.3);
    -o-box-shadow: 0 0 4px rgba(0,0,0,.3);
    box-shadow: 0 0 4px rgba(0,0,0,.3);
    border:5px solid #1ABC9C;
    z-index:9999!important;
    -webkit-border-radius:3px;
    -moz-border-radius:3px;
    -o-border-radius:3px;
    border-radius:3px;
    text-align: center;
    color: #666;

}


.ui-datepicker:before{
    content:"";
    bottom:auto;
    border: solid;
    border-width:16px 16px 16px;
    border-color:transparent transparent #1ABC9C transparent;
    border-color:rgba(255,255,255,0) rgba(255,255,255,0) #1ABC9C rgba(255,255,255,0);
    position:absolute;
    margin-left:-10px;
    display:block;
    top:-32px;
    left:10%;
    height:0;
    width:0;
}


.ui-datepicker a { color: #404040; text-align:center; }
.ui-datepicker .ui-state-disabled span{ color:#D9DDE5;}
.ui-timepicker-div .ui-widget-header,
.ui-datepicker .ui-datepicker-header {
    position: relative;
    background:#1ABC9C;
    line-height: 27px;
    font-size: 15px;
    padding: 10px;
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
    width: 34px;
    height: 34px;
    display: block;
    font-size: 14px;
    position: absolute;
    text-decoration: none;
    cursor: pointer;
    color:#fff;
    top:19.5%;
}

.ui-datepicker .ui-datepicker-prev { left: 2px;  }
.ui-datepicker .ui-datepicker-next { right: 2px; }
.ui-datepicker .ui-datepicker-title {
    margin: 0 2.3em;
    line-height: 1.8em;
    text-align: center;
    color:#fff;
}

.ui-datepicker .ui-datepicker-title select { font-size: 1em; margin: 1px 0; }
.ui-datepicker select.ui-datepicker-month-year { width: 100%; }
.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year { width: 49%; }
.ui-datepicker table {
    width: 100%;
    font-size: .9em;
    margin: 0 0 .4em;
    border-collapse: collapse;
}

.ui-datepicker th {
    padding: .5em .3em;
    text-align: center;
    font-weight: bold;
    border: 0;
}

.ui-datepicker td { border: 0; padding:2px 5px;  }
.ui-datepicker td span,
.ui-datepicker td a {
    padding: .25em;
    display: block;
    text-align: center;
    text-decoration: none;
    -webkit-border-radius:3px;
    -moz-border-radius:3px;
    -o-border-radius:3px;
    border-radius:3px;
}

.ui-datepicker td span:hover,
.ui-datepicker td a:hover {  background:#D7DCDE; }
.ui-datepicker .ui-state-disabled span:hover{ background:none; }

/* @changes to the datepicker and month picker widget in v.4
------------------------------------------------------------- */
.ui-datepicker-today a,
.ui-datepicker-today a:hover,
.ui-datepicker .ui-state-highlight{
    background: #FFFA90!important;
    -webkit-border-radius:3px;
    -moz-border-radius:3px;
    -o-border-radius:3px;
    border-radius:3px;
    font-weight: 700;
    color:#243140;
}

.ui-monthpicker .ui-datepicker-today a,
.ui-monthpicker .ui-datepicker-today a:hover,
.ui-datepicker .ui-datepicker-current-day a{
    font-weight: 700;
    background: #1ABC9C!important;
    -webkit-border-radius:3px;
    -moz-border-radius:3px;
    -o-border-radius:3px;
    border-radius:3px;
    color:#fff;
}

/* @multiple calendars || not responsive use carefully
--------------------------------------------------------------- */
.cal-widget .ui-datepicker { width: 100%; margin-top:0; }
.cal-widget .ui-datepicker:before{ display:none; }
.ui-datepicker.ui-datepicker-multi { width: auto; }
.ui-datepicker-multi .ui-datepicker-group { float: left; }
.ui-datepicker-multi .ui-datepicker-group table { width: 95%; margin: 0 auto .4em; }
.ui-datepicker-multi-2 .ui-datepicker-group { width: 50%; }
.ui-datepicker-multi-3 .ui-datepicker-group { width: 33.333%; }
.ui-datepicker-multi-4 .ui-datepicker-group { width: 25%; }
.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header { border-left-width: 0; }
.ui-datepicker-multi .ui-datepicker-buttonpane { clear: left; }
.ui-datepicker-row-break { clear: both; width: 100%; font-size: 0; }


/* @ ui buttons
---------------------------------------------------------------- */
.ui-datepicker-buttonpane{ border-top:1px solid #D9DDE5; padding:10px;  }
.ui-datepicker-buttonpane button {
    padding: 8px 12px;
    margin-right: .2em;
    position: relative;
    line-height: normal;
    display: inline-block;
    -webkit-user-drag: none;
    text-shadow: 0 1px rgba(255, 255, 255, 0.2);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    vertical-align: middle;
    background: #bdc3c7;
    text-align: center;
    overflow: visible;
    cursor: pointer;
    color: #243140;
    border:0;
}

/* @ ui buttons :hover, :active states
---------------------------------------------------------------- */
.ui-datepicker-buttonpane button:hover { color: #243140; background: #cacfd2; }
.ui-datepicker-buttonpane button:active{ color: #1d2938; background: #a1a6a9; }
.ui-monthpicker .ui-datepicker-header{ margin-bottom:3px; }





.ui-datepicker-week-col {
    background: #e8fffb;
    border-right: 1px solid #5dc89f !important;
}
.ui-datepicker td span, .ui-datepicker td a {
    padding: 0.05em !important;

}

.ui-datepicker table {
    margin: 0 0 0em !important;
}

/*
.ui-datepicker { border:5px solid #3498db; }
.ui-datepicker:before{
    border-color:transparent transparent #3498db transparent;
    border-color:rgba(255,255,255,0) rgba(255,255,255,0) #3498db rgba(255,255,255,0);
}
.ui-timepicker-div .ui-widget-header,
.ui-datepicker .ui-datepicker-header { background:#3498db; }
.ui-monthpicker .ui-datepicker-today a,
.ui-monthpicker .ui-datepicker-today a:hover,
.ui-datepicker .ui-datepicker-current-day a{
    background:#3498db!important;
}*/