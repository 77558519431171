// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~chart.js/dist/Chart.min.css';
//@import '~bootstrap4-toggle/css/bootstrap4-toggle.min.css';

@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import 'datepicker.css';
@import '~select2/src/scss/core';
@import '~@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css';

